import { css } from '@emotion/react';
import { faAngleDown, faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import {
  Box,
  Button,
  desktopFirstMediaQuery,
  Icon,
  Menu,
  Text,
  useTheme,
  type MantineTheme
} from '@inflearn/ds-react';
import { isEmpty } from 'lodash-es';
import { useLocation } from '../../../../layouts/Infcon2023Layout';
import { URLS } from '../../../../utils/urls';
import useMainButtonText from '../hooks/useMainButtonText';

type Props = {
  isRightButtonActive: boolean;
};

const Header = ({ isRightButtonActive }: Props) => {
  const theme = useTheme();
  const dropdownItemStyle = getHeaderDropdownItemStyle(theme);
  const location = useLocation();
  const { buttonText, buttonDisabled } = useMainButtonText();
  const getStyleWhenPathnameIncluded = (url: string | string[]) => {
    if (location == null) {
      return;
    }
    const style = styleActiveButton(theme);
    if (typeof url === 'object') {
      const filteredUrls = url.filter((urlItem) => location.pathname.includes(urlItem));
      if (isEmpty(filteredUrls)) {
        return false;
      }
      return style;
    }

    return location.pathname.includes(url) && style;
  };

  return (
    <Box component="header" css={styleHeader(theme)} id={'infcon-2023-header-pc'}>
      <Box
        css={{
          width: '100%',
          maxWidth: 1200
        }}>
        <Box css={styleHeaderBody(theme)}>
          <a href={URLS.INFCON_2023__HOME}>
            <Text size="lg" weight="bold" color="black">
              INFCON 2023
            </Text>
          </a>
          <Box
            component="ul"
            css={{
              display: 'flex',
              listStyle: 'none'
            }}>
            <Box component="li">
              <a href={URLS.INFCON_2023__SESSION}>
                <Button
                  variant="subtle"
                  color="dark"
                  size="md"
                  radius="md"
                  compact
                  css={[
                    headerButtonStyle,
                    getStyleWhenPathnameIncluded(URLS.INFCON_2023__SESSION)
                  ]}>
                  발표/프로그램
                </Button>
              </a>
            </Box>
            <Box component="li">
              <Menu withArrow styles={{ item: { padding: 0 } }} trigger="hover">
                <Menu.Target>
                  <a href={URLS.INFCON_2023__EVENT}>
                    <Button
                      variant="subtle"
                      color="dark"
                      size="md"
                      radius="md"
                      compact
                      css={[
                        headerButtonStyle,
                        getStyleWhenPathnameIncluded([
                          URLS.INFCON_2023__EVENT,
                          URLS.INFCON_2023__STORY,
                          URLS.INFCON__ACTION
                        ])
                      ]}
                      rightIcon={<Icon icon={faAngleDown} />}>
                      행사 즐기기
                    </Button>
                  </a>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item>
                    <a
                      href={URLS.INFCON_2023__EVENT}
                      css={[
                        dropdownItemStyle,
                        getStyleWhenPathnameIncluded(URLS.INFCON_2023__EVENT)
                      ]}>
                      이벤트
                    </a>
                  </Menu.Item>
                  <Menu.Item>
                    <a
                      href={URLS.INFCON_2023__STORY}
                      css={[
                        dropdownItemStyle,
                        getStyleWhenPathnameIncluded(URLS.INFCON_2023__STORY)
                      ]}>
                      스토리
                    </a>
                  </Menu.Item>
                  <Menu.Item css={{ width: '130px' }}>
                    <Box
                      component="a"
                      href={URLS.INFCON__ACTION}
                      css={[dropdownItemStyle, getStyleWhenPathnameIncluded(URLS.INFCON__ACTION)]}>
                      이것만은 지켜요
                    </Box>
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Box>
            <Box component="li">
              <a href={URLS.INFCON_2023__FAQ}>
                <Button
                  variant="subtle"
                  color="dark"
                  size="md"
                  radius="md"
                  compact
                  css={[headerButtonStyle, getStyleWhenPathnameIncluded(URLS.INFCON_2023__FAQ)]}>
                  FAQ
                </Button>
              </a>
            </Box>
            <Box component="li">
              <a href={URLS.INFCON_2023__MY}>
                <Button
                  variant="subtle"
                  color="dark"
                  size="md"
                  radius="md"
                  compact
                  css={[headerButtonStyle, getStyleWhenPathnameIncluded(URLS.INFCON_2023__MY)]}>
                  MY
                </Button>
              </a>
            </Box>
          </Box>
          <Button
            component="a"
            // TODO: 인프콘 2024때 부활
            // href={URLS.INFCON_2023_COURSE_PAGE}
            href={URLS.INFCON_2023_REPLAY}
            target="_blank"
            // leftIcon={<Icon icon={faBell} />}
            leftIcon={<Icon icon={faArrowUpRightFromSquare} />}
            radius="xl"
            variant={isRightButtonActive ? 'filled' : 'default'}
            color="dark"
            css={{
              transition: 'background 0.2s ease'
            }}
            disabled={buttonDisabled}>
            {buttonText}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const headerButtonStyle = css({
  padding: '0 16px',
  '&:hover': {
    backgroundColor: 'transparent !important'
  }
});

const getHeaderDropdownItemStyle = (theme: MantineTheme) =>
  css({
    display: 'block',
    padding: '10px 12px',
    width: '130px',
    height: '100%',
    color: theme.colors.dark[9],
    fontSize: '16px',
    ':hover': {
      backgroundColor: theme.colors.gray[1]
    }
  });

const styleHeader = (theme: MantineTheme) =>
  css({
    zIndex: 30,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
    width: '100%',
    height: '64px',
    backgroundColor: 'inherit',
    backdropFilter: 'blur(20px)',
    [desktopFirstMediaQuery.mobile]: {
      display: 'none'
    }
  });
const styleHeaderBody = (theme: MantineTheme) =>
  css({
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 auto',
    padding: '0 32px',
    maxWidth: 1200,
    color: theme.colors.gray[7]
  });

const styleActiveButton = (theme: MantineTheme) =>
  css({
    color: theme.colors.blue[7]
  });

export default Header;
